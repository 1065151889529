const generateOptions = (method, body) => {
    return {
        method: method,
        body: body,
        headers: {
            // Aquí configuramos el tipo de contenido como 'multipart/form-data'
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    };
};

export default generateOptions;
