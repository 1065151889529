import Card from "components/card/Card.js";
import { HSeparator } from "components/separator/Separator";
import { Input, Button, Heading, Stack, SimpleGrid, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const NeighborsForm = ({ disabled, data, onValidate }) => {
  const [neighbors, setNeighbors] = useState([{
    name: '',
    first_surname: '',
    second_surname: '',
    email: '',
    phone: '',
    location: '',
    DNI: '',
    property_id: ''
  }]);

  useEffect(() => {
    if(data[0] && data[0].location !== ''){
      setNeighbors(data.map((neighbor) => {
        return {
          name: neighbor.name || '',
          first_surname: neighbor.first_surname || '',
          second_surname: neighbor.second_surname || '',
          email: neighbor.email || '',
          phone: neighbor.phone || '',
          location: neighbor.location || '',
          DNI: neighbor.DNI || '',
          property_id: neighbor.property_id || ''
        }
      }));
    }
  }, [data]);


  const handleInputChange = (index, field, value) => {
    const newNeighbors = [...neighbors];
    newNeighbors[index] = { ...newNeighbors[index], [field]: value };
    setNeighbors(newNeighbors);
  };

  const handleAddNeighbor = () => {
    setNeighbors([...neighbors, {
      name: '',
      first_surname: '',
      second_surname: '',
      email: '',
      phone: '',
      location: '',
      DNI: '',
      property_id: data[0].property_id
    }]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario
    onValidate('neighbors', neighbors);
  };

  return (
    <Card shadow bg={disabled ? "gray.200" : "white"} opacity={disabled ? 0.7 : 1}>
      <Heading as="h2" size="lg" mb={4}>Vecinos</Heading>
      <form onSubmit={handleSubmit} disabled={disabled}>
        <Stack spacing={4} mb={4}>
          {/* <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}> */}
            {neighbors.map((neighbor, index) => (
              <div key={index}>
                {/* <Text as="h3" fontSize="md" mb={4}>Vecino {index + 1 }</Text> */}
                <Input
                  value={neighbor.name}
                  onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                  placeholder={`Nombre del vecino ${index + 1}`}
                  disabled={disabled}
                  mb={2}
                />
                <Input
                  value={neighbor.first_surname}
                  onChange={(e) => handleInputChange(index, 'first_surname', e.target.value)}
                  placeholder={`Primer apellido del vecino ${index + 1}`}
                  disabled={disabled}
                  mb={2}
                />
                <Input
                  value={neighbor.second_surname}
                  onChange={(e) => handleInputChange(index, 'second_surname', e.target.value)}
                  placeholder={`Segundo apellido del vecino ${index + 1}`}
                  disabled={disabled}
                  mb={2}
                />
                <Input
                  value={neighbor.email}
                  onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                  placeholder={`Email del vecino ${index + 1}`}
                  disabled={disabled}
                  mb={2}
                />
                <Input
                  value={neighbor.phone}
                  onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                  placeholder={`Teléfono del vecino ${index + 1}`}
                  disabled={disabled}
                  mb={2}
                />
                <Input
                  value={neighbor.location}
                  onChange={(e) => handleInputChange(index, 'location', e.target.value)}
                  placeholder={`Dirección del vecino ${index + 1}`}
                  disabled={disabled}
                  mb={2}
                />
                <Input
                  value={neighbor.DNI}
                  onChange={(e) => handleInputChange(index, 'DNI', e.target.value)}
                  placeholder={`DNI del vecino ${index + 1}`}
                  disabled={disabled}
                  mb={4}
                />
                <HSeparator />
              </div>
            ))}
          {/* </SimpleGrid> */}
        </Stack>
        <Stack direction="row" spacing={4} mb={4}>
          <Button onClick={handleAddNeighbor} disabled={disabled} flex="1">
            Añadir vecino
          </Button>
          <Button type="submit" disabled={disabled} flex="1">
            Validar
          </Button>
        </Stack>
      </form>
    </Card>
  );
};

export default NeighborsForm;