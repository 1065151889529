// Chakra imports
import { Flex, Text, useColorModeValue, Button } from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import Project from "views/admin/meetings/components/Project";
import NewAgendaDialog from "views/admin/meetings/components/NewAgendaDialog";
import { useFetch } from "hooks";
import EditAgendaDialog from "./EditAgendaDialog";

export default function Agendas({ data }) {
  const [agendasData, setAgendasData] = useState(
    data.map((agenda) => {
      return {
        title: agenda.title,
        description: agenda.description,
        id: agenda.id,
        vote_type_id: agenda.vote_type_id,
        is_informative: agenda.is_informative,
      };
    })
  );

  const [isNewAgendaOpen, setIsNewAgendaOpen] = useState(false);
  const [isEditAgendaOpen, setIsEditAgendaOpen] = useState(false);
  const [agendaItem, setAgendaItem] = useState({});

  const handleNewAgendaClose = (item) => {
    if (item) {
      setIsNewAgendaOpen(false);
      setAgendasData((prevState) => [...prevState, item]);
    } else {
      console.log("something has gone wrong");
    }
  };

  const handleEditAgendaClose = (item) => {
    console.log("edit item, :", item);
    if (item) {
      setIsEditAgendaOpen(false);
      setAgendasData(
        agendasData.map((agenda) => {
          if (item.id === agenda.id) {
            return {
              title: item.title,
              description: item.description,
              id: item.id,
              vote_type_id: item.vote_type_id,
              is_informative: item.is_informative,
            };
          }
          return agenda;
        })
      );
    } else {
      console.log("something has gone wrong");
    }
  };

  const handleOpenDialog = () => setIsNewAgendaOpen(true);

  const handleEdit = (item) => {
    setIsEditAgendaOpen(true);
    setAgendaItem(item);
  };

  const handleDelete = (item) => {
    console.log("delete", item);
  };

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex justify="space-between" mb="20px" align="center">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Puntos del día por defecto
        </Text>
        <Button colorScheme="blue" onClick={handleOpenDialog}>
          Nuevo punto del día
        </Button>
      </Flex>

      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        Aquí puedes ver los distintos puntos del día creados para las juntas.
      </Text>
      {agendasData.map((agenda, i) => {
        return (
          <Project
            key={agenda.title + i}
            boxShadow={cardShadow}
            mb="20px"
            ranking={i}
            item={agenda}
            link="#"
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        );
      })}
      <NewAgendaDialog
        isOpen={isNewAgendaOpen}
        onClose={handleNewAgendaClose}
      />
      {agendaItem.title && (
        <EditAgendaDialog
          isOpen={isEditAgendaOpen}
          onClose={handleEditAgendaClose}
          item={agendaItem}
        />
      )}
    </Card>
  );
}
