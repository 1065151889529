import React, { useState } from "react";
import { VStack, HStack, Button, useColorModeValue } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon, CheckIcon } from "@chakra-ui/icons";

const getButtonText = (currentStep, steps = 0) => {
  const isLastStep = currentStep === steps.length - 1;
  const isFirstStep = currentStep === 0; 
  if(isLastStep) return 'Finalizar junta'

  if(isFirstStep) return 'Empezar Junta'
  
  return 'Siguiente';
};

const Stepper = ({ children, onCallback }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = React.Children.toArray(children);
  const isLastStep = currentStep === steps.length - 1;
  const finishColorScheme = isLastStep ? "green" : "blue";
  const finishIcon = isLastStep ? <CheckIcon /> : <ChevronRightIcon />;

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFinish = () => {
    onCallback();
  };

  return (
    <VStack
      mt={{ base: "130px", md: "80px", xl: "80px" }}
      align="start"
      spacing={6}
      borderRadius="md"
    >
      {steps[currentStep]}
      <HStack justify="space-between" w="100%">
        <Button
          onClick={handlePrev}
          leftIcon={<ChevronLeftIcon />}
          disabled={currentStep === 0}
        >
          Anterior
        </Button>
        <Button
          onClick={isLastStep ? handleFinish : handleNext}
          rightIcon={finishIcon}
          colorScheme={finishColorScheme}
        >
          {getButtonText(currentStep, steps)}
        </Button>
      </HStack>
    </VStack>
  );
};

export default Stepper;
