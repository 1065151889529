export const neighborhoodDataFallback = {
  neighborhood: {
    name: '',
    cif: '',
    location: ''
  },
  properties: [
    {
      location: '',
      neighbor: '',
      neighbor_id: ''
    }
  ],
  neighbors: [
    {
      name: '',
      email: '',
      first_surname: '',
      second_surname: '',
      location: '',
      DNI: '',
      phone: '',
      property_id: ''
    }
  ]
}