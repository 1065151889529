const getNeighborDebt = (properties) => {
  return properties.reduce((accumulator, property) => {
    // Verificar si la propiedad tiene una deuda válida y mayor que cero
    const debt = property.debt
      ? parseFloat(property.debt.replace(",", "."))
      : 0;
    if (!isNaN(debt) && debt > 0) {
      // Sumar la deuda de la propiedad al acumulador
      return accumulator + debt;
    } else {
      // Si la deuda no es válida o es cero, mantener el valor actual del acumulador
      return accumulator;
    }
  }, 0);
};

export default getNeighborDebt;
