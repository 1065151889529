import { Box, Flex, Text, Badge, Button } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router-dom";

const Record = ({item}) => {
  console.log('the item: ', item);
  const {title, neighborhood, date, is_confirmed, id} = item;
  const history = useHistory();

  const handleClick = () => {
    history.push('./actas/'+id);
  };

  return (
    <Card
      mb={{ base: "20px", "2xl": "20px" }}
    >
      {!is_confirmed && (
        <Badge
          position="absolute"
          top="4"
          right="4"
          variant="outline"
          colorScheme="yellow"
          fontSize="xs"
        >
          Borrador
        </Badge>
      )}
      {/* Botón a la derecha del contenido */}
      <Flex align="bottom" justify="space-between">
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb="2">
            {title}
          </Text>
          <Text fontSize="sm" fontWeight="bold" mb="2">
            Comunidad: {neighborhood.name}
          </Text>
          <Text fontSize="sm" color="gray.600" mb="2">
            Fecha: {date}
          </Text>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" size="sm" onClick={handleClick}>
            Ver detalle
          </Button>
        </Box>
      </Flex>
    </Card>
  );
};

export default Record;
