/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  SimpleGrid
} from "@chakra-ui/react";

import MeetingDetails from "views/admin/meeting/components/MeetingDetails";
import MeetingStartPod from "views/admin/meeting/components/MeetingStartPod";
import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import Loader from 'components/Loader';

import { useFetch } from "hooks";
import { useParams } from "react-router-dom";

export default function Meeting() {
  const [meetingsUrl, setMeetingsUrl] = useState("");
  const { data, loading, error } = useFetch(meetingsUrl);
  const { meetingId } = useParams();

  useEffect(() => {
    setMeetingsUrl("/api/meetings/" + meetingId);
  }, []);

  // Chakra Color Mode
  if (loading || !data) {
    return <Loader />;
  }

  return (
    <>
      <Box textAlign="left" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1, lg: 2, base: 2}}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <Card mb={{ base: "0px", "2xl": "20px" }}>
            <MeetingDetails data={data}/>
          </Card>
          <Card bg="gray.500" mb={{ base: "0px", "2xl": "20px" }}>
            <MeetingStartPod data={data}/>
          </Card>
        </SimpleGrid>
      </Box>
    </>
  );
}
