import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import useFetch from "hooks/useFetch";
import { titleFallback, meetingTypeFallback } from "./constants";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
  SimpleGrid,
  useColorModeValue,
  Textarea,
  Switch,
  RadioGroup,
  Radio,
  Stack,
} from "@chakra-ui/react";
import SwitchField from "components/fields/SwitchField";
import ConfirmationDialog from "./components/confirmationDialog";
import Loader from "components/Loader";
import { generateOptions, addMinutesToTime } from "utils";

const AtendeesForm = ({ onCallback, attendees }) => {
  const [neighbors, setNeighbors] = useState(attendees);

  const handleChange = (event, property, attendeeIndex, propertyIndex) => {
    const { value } = event.target;
    const newNeighbors = [...neighbors];
    newNeighbors[attendeeIndex].properties[propertyIndex].debt = value;
    setNeighbors(newNeighbors);
  };

  useEffect(() => {
    onCallback(neighbors);
  }, [neighbors]);

  return (
    <Box mt={2} columns={{ base: 1, md: 2, sm: 1, lg: 3 }} spacing={4}>
      {neighbors.map((attendee, attendeeIndex) => (
        <SimpleGrid
          key={attendee.id}
          borderWidth="1px"
          borderRadius="lg"
          p="4"
          mb="4"
        >
          {/* Nombre de usuario */}
          <Text fontSize="xl" fontWeight="bold" mb="2">
            {attendee.name} {attendee.first_surname}
          </Text>

          {/* Propiedades asociadas */}
          {attendee.properties.map((property, propertyIndex) => (
            <SimpleGrid
              key={property.id}
              mt={2}
              columns={{ base: 1, md: 3, sm: 1, lg: 3 }}
              spacing={4}
            >
              <FormLabel>
                Propiedad:
                <Input
                  placeholder="Localización"
                  type="text"
                  disabled="true"
                  value={property.location}
                />
              </FormLabel>
              <FormLabel>
                Coeficiente (%):
                <Input
                  placeholder="Coeficiente"
                  type="number"
                  disabled="true"
                  value={property.ratio}
                />
              </FormLabel>
              <FormLabel>
                Deuda del inmueble:
                <Input
                  placeholder="Deuda"
                  type="number"
                  value={property.debt}
                  onChange={(event) =>
                    handleChange(event, property, attendeeIndex, propertyIndex)
                  }
                />
              </FormLabel>
            </SimpleGrid>
          ))}
        </SimpleGrid>
      ))}
    </Box>
  );
};

const AgendaForm = ({ onCallback, bankName, iban }) => {
  const [agendaItems, setAgendaItems] = useState([]);
  const [agendaUrl, setAgendaUrl] = useState("");
  const { data, loading, error } = useFetch(agendaUrl);
  const [newItemTitle, setNewItemTitle] = useState("");
  const [newItemDescription, setNewItemDescription] = useState("");
  const [isInformative, setIsInformative] = useState(false);
  const [voteTypeId, setVoteTypeId] = useState("3");
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    setAgendaUrl("/api/agendas");
  }, []);

  useEffect(() => {
    if (data) {
      setAgendaItems(
        data.map((item) => {
          return {
            title: item.title,
            description: item.description,
            id: item.id,
            public_notes: item.public_notes || "",
            private_notes:
              item.id !== 1
                ? item.private_notes
                : "Cuenta bancaria a la que tendrán acceso los nuevos cargos:\n" +
                  bankName +
                  ": " +
                  iban,
            checked: true,
            is_informative: !item.is_informative,
            vote_type_id: item.vote_type_id,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (agendaItems.length > 0) onCallback(agendaItems);
  }, [agendaItems]);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const handleChange = (item) => {
    setAgendaItems((prevState) => {
      return prevState.map((ps) => {
        if (ps.id === item.id) {
          return { ...item, checked: !item.checked };
        }
        return ps;
      });
    });
  };

  const handleAddItem = () => {
    if (!newItemTitle.trim() || !newItemDescription.trim()) {
      alert("Por favor, completa el título y la descripción.");
      return;
    }
    const newItem = {
      id: "n" + agendaItems.length + 1,
      title: newItemTitle,
      description: newItemDescription,
      public_notes: "",
      private_notes: "",
      checked: true,
      is_informative: false,
      vote_type_id: "3",
    };
    setAgendaItems([...agendaItems, newItem]);
    setNewItemTitle("");
    setNewItemDescription("");
    setShowAddForm(false);
  };

  const handleNotes = (text, item, type) => {
    console.log(type);
    setAgendaItems((prevState) => {
      return prevState.map((ps) => {
        if (ps.id === item.id) {
          return {
            ...ps,
            [type]: text,
          };
        }
        return ps;
      });
    });
  };

  const handleCleanPublicNotes = () => {
    console.log("clean");
  };

  if (loading || !agendaItems) return <p>Loading...</p>;

  if (error) return <p>Error!!!</p>;
  return (
    <>
      <Flex align="center" w="100%" justify="space-between" mb="30px">
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mb="4px"
          mt={2}
        >
          Selecciona los puntos de la órden del día que deseas llevar a la junta
        </Text>
      </Flex>
      {agendaItems.map((item) => {
        return (
          <div key={item.title + item.id}>
            <SwitchField
              isChecked={item.checked}
              fontSize="sm"
              mb="20px"
              id={item.id}
              label={item.title}
              onChange={() => handleChange(item)}
            />
            <HStack justify="space-between" mb={4}>
              <Box flex="1">
                <Textarea
                  value={item.public_notes}
                  onChange={(e) =>
                    handleNotes(e.target.value, item, "public_notes")
                  }
                  placeholder="Anotaciones públicas"
                  size="md"
                  mb={4}
                />
              </Box>
              <Box flex="1">
                <Textarea
                  value={item.private_notes}
                  onChange={(e) =>
                    handleNotes(e.target.value, item, "private_notes")
                  }
                  placeholder="Anotaciones privadas"
                  size="md"
                  mb={4}
                />
              </Box>
            </HStack>
          </div>
        );
      })}
      <Button mt={2} onClick={() => setShowAddForm(!showAddForm)}>
        Añadir punto del día
      </Button>
      {showAddForm && (
        <FormControl mb="20px">
          <FormLabel>Título</FormLabel>
          <Input
            value={newItemTitle}
            onChange={(e) => setNewItemTitle(e.target.value)}
            placeholder="Título del punto del día"
          />
          <FormLabel mt={2}>Descripción</FormLabel>
          <Input
            value={newItemDescription}
            onChange={(e) => setNewItemDescription(e.target.value)}
            placeholder="Descripción del punto del día"
          />
          <Button mt={2} onClick={handleAddItem}>
            Añadir
          </Button>
          <FormLabel>Se puede votar este punto?</FormLabel>
          <Switch
            name="is_informative"
            isChecked={!isInformative}
            onChange={() => setIsInformative(!isInformative)}
          />

          {!isInformative && (
            <>
              <FormLabel>Seleccione el tipo de votación</FormLabel>
              <RadioGroup
                colorScheme="blue"
                value={voteTypeId}
                onChange={(value) => setVoteTypeId(value)}
              >
                <Stack spacing={2}>
                  <Radio value="1">Unanimidad</Radio>
                  <Radio value="2">Mayoría reforzada</Radio>
                  <Radio value="3">Mayoría simple</Radio>
                  <Radio value="4">Un tercio</Radio>
                </Stack>
              </RadioGroup>
            </>
          )}
        </FormControl>
      )}
    </>
  );
};

const MeetingAdjustments = ({ onCallback, data }) => {
  const [messageUrl, setMessageUrl] = useState("");
  const [options, setOptions] = useState({});
  const { data: inviteMessage, loading, error } = useFetch(messageUrl, options);

  const generarMensajeIA = () => {
    const body = {
      meeting_type: data.meeting_type,
      meeting_date: data.date,
      meeting_time: data.proposedTime1,
      meeting_time_2: data.proposedTime2,
      meeting_location: data.location,
    };

    setOptions(generateOptions("POST", JSON.stringify(body)));
    setMessageUrl("/api/openai/generate-meeting-invite");
  };

  useEffect(() => {
    if (inviteMessage) {
      onCallback(inviteMessage.content);
    }
  }, [inviteMessage]);

  useEffect(() => {
    if (options.method) {
      setMessageUrl("/api/openai/generate-meeting-invite");
    }
  }, [options]);

  const handleInputChange = (event) => onCallback(event.target.value);

  const handleLimpiarMensaje = () => onCallback("");

  return (
    <Card mt={2} p={0}>
      {loading && <Loader />}
      {!loading && data && (
        <Textarea
          value={data.meetingDescription}
          onChange={handleInputChange}
          placeholder="Escribe tu mensaje personalizado de convocatoria aquí..."
          mb={4}
        />
      )}

      <HStack justify="space-between" mb={4}>
        <Button flex="1" colorScheme="red" onClick={handleLimpiarMensaje}>
          Limpiar
        </Button>
        <Button flex="1" colorScheme="teal" onClick={generarMensajeIA}>
          Generar con IA
        </Button>
      </HStack>
    </Card>
  );
};

const NewMeeting = () => {
  const { neighborhoodId } = useParams();
  const [formData, setFormData] = useState({
    title: titleFallback,
    meeting_type: meetingTypeFallback,
    location: "",
    date: "",
    proposedTime1: "",
    proposedTime2: "",
    meetingDescription: "",
    neighbors: [],
    agendaItems: [],
    neighborhood: "",
  });
  const [neighborhoodUrl, setNeigborhoodUrl] = useState("");
  const { data, loading, error } = useFetch(neighborhoodUrl);
  const [neighborsUrl, setNeighborsUrl] = useState("");
  const { data: neighborsData, error: neighborsError } = useFetch(neighborsUrl);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const handleConfirmMeetingData = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleAttendeesChange = (attendees) => {
    console.log("hola", attendees);
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        neighbors: attendees,
      };
    });
  };

  const handleAgendaChange = (agendaItems) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        agendaItems: agendaItems,
      };
    });
  };

  const handleMessageChange = (message) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        meetingDescription: message,
      };
    });
  };

  useEffect(() => {
    if (data) {
      setFormData((prevState) => {
        return {
          ...prevState,
          neighborhood: data.name,
          location: data.location,
          IBAN: data.IBAN,
          bankName: data.bank_name,
        };
      });
    }
  }, [data]);

  useEffect(() => {
    if (neighborsData) {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          neighbors: neighborsData,
        };
      });
    }
  }, [neighborsData]);

  useEffect(() => {
    setNeigborhoodUrl("/api/neighborhoods/" + neighborhoodId);
    setNeighborsUrl("/api/neighborhoods/" + neighborhoodId + "/neighbors");
  }, [neighborhoodId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        [name]: value,
      };

      if (name === "proposedTime1")
        updatedFormData.proposedTime2 = addMinutesToTime(value, 30);

      return updatedFormData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsConfirmationDialogOpen(true);
    // Aquí puedes manejar el envío del formulario y enviar formData a la base de datos
  };

  if (loading || !formData.location) {
    return <Loader />;
  }

  return (
    <>
      <Card p={0} mt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Datos básicos
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel mt={2} pb={4}>
              <SimpleGrid columns={{ base: 2, sm: 1, md: 2 }} spacing={4}>
                {/* Primera columna */}
                <FormControl>
                  <FormLabel>Comunidad</FormLabel>
                  <Input
                    name="community"
                    value={formData.neighborhood}
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Título de junta</FormLabel>
                  <Input
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Tipo de junta</FormLabel>
                  <Select
                    name="meeting_type"
                    value={formData.meeting_type}
                    onChange={handleInputChange}
                  >
                    <option value="Ordinaria">Ordinaria</option>
                    <option value="Extraordinaria">Extraordinaria</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Lugar</FormLabel>
                  <Input
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Fecha</FormLabel>
                  <Input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Hora propuesta 1</FormLabel>
                  <Input
                    type="time"
                    name="proposedTime1"
                    value={formData.proposedTime1}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Hora propuesta 2</FormLabel>
                  <Input
                    type="time"
                    name="proposedTime2"
                    value={formData.proposedTime2 || formData.proposedTime1}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </SimpleGrid>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Asistentes (Propietarios de la comunidad)
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {formData && formData.neighbors.length > 0 && (
                <AtendeesForm
                  onCallback={handleAttendeesChange}
                  attendees={formData.neighbors}
                />
              )}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Órden del día
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <AgendaForm
                onCallback={handleAgendaChange}
                bankName={formData.bankName}
                iban={formData.IBAN}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Mensaje de convocatoria
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <MeetingAdjustments
                onCallback={handleMessageChange}
                data={formData}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Button
          mt="20px"
          type="submit"
          colorScheme="blue"
          onClick={handleSubmit}
          mr={4}
        >
          Enviar convocatoria
        </Button>
      </Card>
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmMeetingData}
        meetingData={formData}
      />
    </>
  );
};

export default NewMeeting;
