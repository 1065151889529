import { useState, useEffect } from "react";
import { getApiUrl } from "utils";

const defaultOptions = {
    headers: {
        Authorization: localStorage.token,
    },
};

function useFetch(url, options = defaultOptions) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const mergedOptions = {
                    ...options,
                    headers: {
                        ...options.headers,
                        Authorization: localStorage.token,
                    },
                };
                const response = await fetch(getApiUrl() + url, mergedOptions);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const responseData = await response.json();
                setData(responseData);
            } catch (error) {
                console.error(error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        url && fetchData();
        // Cleanup function
        return () => {
            // Optionally you can cancel any pending fetch requests here
        };
    }, [url, options]);

    return { data, loading, error };
}

export default useFetch;
