// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Spinner
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
// Assets
import { MdUpload } from "react-icons/md";
import { CheckIcon } from '@chakra-ui/icons';
import Dropzone from "views/admin/newNeighborhoodAI/components/Dropzone";
import useFetch from 'hooks/useFetch';

export default function Upload(props) {
  const { onUpload, ...rest } = props;
  const [file, setFile] = useState();
  const [creationData, setCreationData] = useState();
  const [convertUrl, setConvertUrl] = useState('');
  const [options, setOptions] = useState({});
  const { data, loading, error } = useFetch(convertUrl, options);

  const handleUpload = (file) => {
    console.log('the fileee: ', file);
    const formData = new FormData();
    formData.append('archivo_pdf', file);

    try {
      // Configura las opciones para la solicitud POST de multipart/form-data
      const options = {
        method: 'POST',
        body: formData,
        headers: {
          // Aquí configuramos el tipo de contenido como 'multipart/form-data'
          Accept: 'application/json'
        }
      };
      setOptions(options);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (options.method) {
      setConvertUrl('/api/convertir-pdf', options);
    }
  }, [options]);

  useEffect(() => {
    if (data) {
      onUpload(data.choices[0].message.content);
    }
  }, [data]);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  return (
    <Card {...rest} mb='20px' align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Dropzone
          setFile={handleUpload}
          w={{ base: "100%", "2xl": "100%" }}
          me='36px'
          maxH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          content={
            <Box>
              {loading && !data && <Spinner w='80px' h='80px' color={brandColor} />}
              {!loading && !data && <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />}
              {data && <Icon as={CheckIcon} w='80px' h='80px' color="green" />}
              <Flex justify='center' mx='auto' mb='12px'>
                <Text fontSize='xl' fontWeight='700' color={brandColor}>
                  {!loading && !data && 'Sube la última acta!'}
                  {loading && !data && 'Tu acta se está procesando, espera por favor'}
                  {data && 'Acta procesada, subela de nuevo si quieres que se vuelvan a extraer tus datos.'}
                </Text>
              </Flex>
              <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                Únicamente el formato PDF está permitido... de momento :)
              </Text>
            </Box>
          }
        />
        <Flex direction='column' pe='44px'>
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            textAlign='start'
            fontSize='2xl'
            mt={{ base: "20px", "2xl": "50px" }}>
            Importar datos con IA
          </Text>
          <Text
            color={textColorSecondary}
            fontSize='md'
            my={{ base: "auto", "2xl": "10px" }}
            mx='auto'
            textAlign='start'>
            Para continuar debes adjuntar el acta de la última junta de vecinos ordinaria que hayas realizado en esta comunidad,
          </Text>

        </Flex>
      </Flex>
    </Card>
  );
}
