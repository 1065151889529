import { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import FilterableSelect from 'components/filterableSelect';
import useFetch from 'hooks/useFetch';
import { useHistory } from 'react-router-dom';

const NeighborhoodSelectionDialog = ({ isOpen, onClose }) => {
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [neighborhoodsData, setNeighborhoodsData] = useState([]);
  const [neighborhoodsUrl, setNeighborhoodsUrl] = useState('');
  const { data, loading, error } = useFetch(neighborhoodsUrl);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      const result = data.map((neighborhood) => {
        return {
          name: neighborhood.name,
          address: neighborhood.location,
          cif: neighborhood.cif,
          status: neighborhood.status,
          id: neighborhood.id
        }
      });
      setNeighborhoodsData(result);
    }
  },[data]);

  useEffect(() => {
    setNeighborhoodsUrl('/api/neighborhoods')
  },[]);
  
  // Chakra Color Mode
  if (loading || !neighborhoodsData) {
    return <p>Loading...</p>
  }

  const handleContinue = () => {
    // Aquí puedes manejar la lógica para continuar con la selección de la comunidad
    const selectedNeighborhoodData = neighborhoodsData.find((neighborhood) => neighborhood.name === selectedCommunity);
    // Aquí puedes redirigir a la nueva ruta
    history.push(`juntas/${selectedNeighborhoodData.id}/nueva-junta`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selección de comunidad</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            A continuación, rellenaremos los datos básicos de la junta que deseas 
            convocar, pero antes, selecciona la comunidad sobre la que se convocará 
            la junta
          </p>
          <FilterableSelect
            options={neighborhoodsData.map(community => ({ value: community.id, label: community.name }))}
            onChange={(option) => setSelectedCommunity(option)}
            placeholder="Selecciona una comunidad"
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          <Button colorScheme="blue" onClick={handleContinue}>Continuar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NeighborhoodSelectionDialog;
