/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid, Alert, AlertIcon } from "@chakra-ui/react";
import Agendas from "views/admin/meetings/components/Agendas";
import NextMeetings from "views/admin/meetings/components/NextMeetings";
import React, { useEffect, useState } from "react";
import Loader from "components/Loader";
import { useFetch } from "hooks";
import { useLocation } from "react-router-dom";

const messages = {
  success: "La junta ha sido convocada correctamente",
  finished: "La junta ha concluido satisfactoriamente, el borrador está listo",
};

export default function Meetings() {
  const [meetingsData, setMeetingsData] = useState([]);
  const [meetingsUrl, setMeetingsUrl] = useState("");
  const { data, loading, error } = useFetch(meetingsUrl);
  const [agendasUrl, setAgendasUrl] = useState("");
  const { data: agendasResponseData, error: agendasError } =
    useFetch(agendasUrl);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");

  useEffect(() => {
    if (data) {
      const result = data.map((meeting) => {
        return {
          name: meeting.title,
          meeting_type: meeting.meeting_type,
          location: meeting.location,
          date: meeting.date,
          proposedTime1: meeting.proposedTime1,
          proposedTime2: meeting.proposedTime2,
          meetingDescription: meeting.meetingDescription,
          id: meeting.id,
        };
      });
      setMeetingsData(result);
    }
  }, [data]);

  useEffect(() => {
    setMeetingsUrl("/api/meetings?is_finished=false");
    setAgendasUrl("/api/agendas");
  }, []);

  // Chakra Color Mode
  if (loading || !meetingsData || !agendasResponseData) {
    return <Loader />;
  }

  return (
    <>
      {message && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Alert
            status="success"
            variant="subtle"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon />
            {messages[message]}
          </Alert>
        </Box>
      )}

      <Box
        pt={
          !message
            ? { base: "130px", md: "80px", xl: "80px" }
            : { base: "20px", md: "20px", xl: "20px" }
        }
      >
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 2, lg: 2, base: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          {/* <MeetingsTable
              columnsData={columnsDataComplex}
              tableData={meetingsData}
            /> */}
          <NextMeetings data={meetingsData} />
          <Agendas data={agendasResponseData} />
        </SimpleGrid>
      </Box>
    </>
  );
}
