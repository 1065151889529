import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Switch,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useFetch } from "hooks";

const NewAgendaDialog = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    is_informative: false,
    vote_type_id: "3",
  });
  const [agendasUrl, setAgendasUrl] = useState("");
  const [options, setOptions] = useState({});
  const { data, loading, error } = useFetch(agendasUrl, options);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSwitchChange = () => {
    setFormData({ ...formData, is_informative: !formData.is_informative });
  };

  const handleSubmit = () => {
    // Validar que el título no esté vacío
    if (!formData.title.trim()) {
      alert("El título es obligatorio.");
      return;
    }

    const body = JSON.stringify({
      title: formData.title,
      description: formData.description,
      is_informative: formData.is_informative,
      vote_type_id: parseInt(formData.vote_type_id),
    });

    const options = {
      method: "POST",
      body: body,
      headers: {
        // Aquí configuramos el tipo de contenido como 'multipart/form-data'
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    if (options.method) {
      setAgendasUrl("/api/agendas");
    }
  }, [options]);

  useEffect(() => {
    if (data && !error) onClose(data);
  }, [data]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Nuevo punto de Junta</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Título</FormLabel>
            <Input
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Escribe el título"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Descripción</FormLabel>
            <Textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Dame una descripción"
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Se puede votar este punto?</FormLabel>
            <Switch
              name="is_informative"
              isChecked={!formData.is_informative}
              onChange={handleSwitchChange}
            />
          </FormControl>
          {!formData.is_informative && (
            <FormControl mb={4}>
              <FormLabel>Seleccione el tipo de votación</FormLabel>
              <RadioGroup
                colorScheme="blue"
                value={formData.vote_type_id}
                onChange={(value) =>
                  setFormData({ ...formData, vote_type_id: value })
                }
              >
                <Stack spacing={2}>
                  <Radio value="1">Unanimidad</Radio>
                  <Radio value="2">Mayoría reforzada</Radio>
                  <Radio value="3">Mayoría simple</Radio>
                  <Radio value="4">Un tercio</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <Button w="50%" onClick={onClose}>
            Cancelar
          </Button>
          <Button w="50%" colorScheme="blue" mr={3} onClick={handleSubmit}>
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewAgendaDialog;
