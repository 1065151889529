/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect } from "react";

// Chakra imports
import {
  Box,
  useColorModeValue,
  Grid
} from "@chakra-ui/react";

// Custom components
import Upload from "views/admin/newNeighborhoodAI/components/Upload";
import NeighborhoodForm from './components/NeighborhoodForm';
import NeighborsForm from './components/NeighborsForm';
import PropertiesForm from './components/PropertiesForm';
import Banner from "views/admin/profile/components/Banner";
import Storage from "views/admin/profile/components/Storage";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import { neighborhoodDataFallback } from './constants';
import { RiContactsBookLine } from "react-icons/ri";
import useFetch from 'hooks/useFetch';

function NewNeighborhoodAI() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [neighborhoodUrl, setNeighborhoodUrl] = useState('');
  const [options, setOptions] = useState({});
  const { data, loading, error } = useFetch(neighborhoodUrl, options);
  const [ isDisabled, setIsDisabled] = useState(true);
  const [ neighborhoodData, setNeighborhoodData ] = useState(neighborhoodDataFallback);
  const [validations, setValidations] = useState({
    neighborhood: false,
    properties: false,
    neighbors: false
  });
  const handleUpload = (data) => {
    console.log('the data', JSON.parse(data));
    setNeighborhoodData(JSON.parse(data));
    setIsDisabled(false);
  }

  const handleValidate = (key, data) => {
    if(key === 'neighborhood')
    setNeighborhoodData(prevState => ({
      ...prevState,
      [key]: {...data}
    }));
    else {
      setNeighborhoodData(prevState => ({
        ...prevState,
        [key]: [...data]
      }));
    }
    setValidations(prevState => ({
      ...prevState,
      [key]: true
    }));
  };

  useEffect(() => {
    if(validations.neighborhood && validations.properties && validations.neighbors) {
      console.log('nd', neighborhoodData);
      const body = JSON.stringify({
        neighborhood: {
          name: neighborhoodData.neighborhood.location,
          location: neighborhoodData.neighborhood.location,
          cif: neighborhoodData.neighborhood.cif,
          properties: neighborhoodData.properties,
          neighbors: neighborhoodData.neighbors
        }
      });
      
      const options = {
        method: 'POST',
        body: body,
        headers: {
          // Aquí configuramos el tipo de contenido como 'multipart/form-data'
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      };
      setOptions(options);
    }
  },[validations]);

  useEffect(() => {
    setNeighborhoodUrl('/api/neighborhoods/ai');
  },[options]);

  return (
      <Box 
        mt={{ base: "130px", md: "80px", xl: "80px" }}
        ml={{ base: "15px", md: "15px" }} 
        mr={{ base: "15px", md: "15px" }}
      >
        <Grid
          templateColumns={{
            base: "1fr",
            lg: "1fr",
          }}
        >  
        <Upload
          onUpload={handleUpload}
          gridArea={{
            base: "1 / 1 / 4 / 2",
            lg: "1",
          }}
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        />
      </Grid>
      
      <Grid mb="20px" templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
        <NeighborhoodForm onValidate={handleValidate} data={neighborhoodData.neighborhood} disabled={isDisabled} />
        <PropertiesForm onValidate={handleValidate} data={neighborhoodData.properties} disabled={isDisabled} />
        <NeighborsForm onValidate={handleValidate} data={neighborhoodData.neighbors} disabled={isDisabled} />
      </Grid>
    </Box>
  );
}

export default NewNeighborhoodAI;
