import { useState } from 'react';
import { Input, Box, Text, List, ListItem, ListIcon, Select } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

const FilterableSelect = ({ options, onChange, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    setIsVisible(true);

    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(value)
    );
    setFilteredOptions(filtered);
  };

  const handleSelectOption = (value) => {
    setIsVisible(false);
    setSearchTerm(value);
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <>
      <Input
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleSearchChange}
        mb={4}
      />
      <Box position="relative">
        {isVisible && (
          <List
            position="absolute"
            zIndex="1"
            bg="white"
            borderRadius="md"
            boxShadow="md"
            mt={1}
            w="100%"
          >
            {filteredOptions.map(({label}, i) => (
              <ListItem key={'fs-'+i} onClick={() => handleSelectOption(label)} cursor="pointer" px={3} py={1} _hover={{ bg: 'gray.100' }}>
                <Text>{label}</Text>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </>
  );
};

export default FilterableSelect;
