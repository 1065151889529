import { Box, Alert, AlertIcon } from "@chakra-ui/react";
import Record from "./components/Record";
import React, { useEffect, useState } from "react";
import { useFetch } from "hooks";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader";

const Records = () => {
  const [meetingsUrl, setMeetingsUrl] = useState("");
  const { data, loading, error } = useFetch(meetingsUrl);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("is_confirmed");

  useEffect(() => {
    setMeetingsUrl("/api/meetings?is_finished=true");
  }, []);

  // Chakra Color Mode
  if (loading || !data) {
    return <Loader />;
  }

  return (
    <Box mt={{ base: "130px", md: "80px", xl: "80px" }}>
      {message && (
        <Box mt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Alert
            status="success"
            variant="subtle"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <AlertIcon />
            El acta se ha enviado correctamente al presidente de la comunidad
          </Alert>
        </Box>
      )}

      {data.map((meeting, i) => {
        return <Record key={i} item={meeting} />;
      })}
    </Box>
  );
};

export default Records;
