import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdMeetingRoom
} from "react-icons/md";

import { FaBuilding, FaHandshake, FaBook } from "react-icons/fa";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Neighborhoods from "views/admin/neighborhoods";
import Meetings from "views/admin/meetings";
import NewMeeting from "views/admin/newMeeting";
import Meeting from "views/admin/meeting";
import MeetingRoom from "views/admin/meetingRoom";
import NewNeighborhoodAI from "views/admin/newNeighborhoodAI";
import Records from "views/admin/Records";
import Record from "views/admin/Record";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";

export const hiddenRoutes = ['Dashboard', "Nueva comunidad", "Nueva junta", 'Junta', 'Sala de Juntas', 'Acta', 'Detalle de junta', 'Sign In'];

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Nueva comunidad",
    layout: "/admin",
    icon: <Icon as={FaBuilding} width='20px' height='20px' color='inherit' />,
    path: "/comunidades/nueva-comunidad-ia",
    component: NewNeighborhoodAI,
  },
  {
    name: "Comunidades",
    layout: "/admin",
    icon: <Icon as={FaBuilding} width='20px' height='20px' color='inherit' />,
    path: "/comunidades",
    component: Neighborhoods,
  },
  {
    name: "Nueva junta",
    layout: "/admin",
    icon: <Icon as={FaBuilding} width='20px' height='20px' color='inherit' />,
    path: "/juntas/:neighborhoodId/nueva-junta",
    component: NewMeeting,
  },
  {
    name: "Sala de Juntas",
    layout: "/admin",
    icon: <Icon as={FaBuilding} width='20px' height='20px' color='inherit' />,
    path: "/meeting-room/:meetingId",
    component: MeetingRoom,
  },
  {
    name: "Detalle de junta",
    layout: "/admin",
    icon: <Icon as={FaBuilding} width='20px' height='20px' color='inherit' />,
    path: "/juntas/:meetingId",
    component: Meeting,
  },
  {
    name: "Juntas",
    layout: "/admin",
    icon: <Icon as={FaHandshake} width='20px' height='20px' color='inherit' />,
    path: "/juntas",
    component: Meetings,
  },
  {
    name: "Acta",
    layout: "/admin",
    icon: <Icon as={FaBuilding} width='20px' height='20px' color='inherit' />,
    path: "/actas/:recordId",
    component: Record,
  },
  {
    name: "Actas",
    layout: "/admin",
    icon: <Icon as={FaBook} width='20px' height='20px' color='inherit' />,
    path: "/actas",
    component: Records,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;