import { useState, useEffect } from "react";
import {
  Heading,
  Text,
  Box,
  Button,
  VStack,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Stepper from "components/stepper";
import Step from "./components/Step";
import FinishDialog from "./components/FinishDialog";
import { useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";

const MeetingRoom = () => {
  const [meetingsUrl, setMeetingsUrl] = useState("");
  const {
    data: meetingData,
    loading: meetingLoader,
    error: meetingError,
  } = useFetch(meetingsUrl);
  const [registerVoteUrl, setRegisterVoteUrl] = useState("");
  const [options, setOptions] = useState({});
  const {
    data: voteData,
    loading: voteLoader,
    error: voteError,
  } = useFetch(registerVoteUrl, options);
  const { meetingId } = useParams();

  const [isOpen, setIsOpen] = useState(false);

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  // TODO in near future
  // const handleRefresh = () => {
  //   console.log("información actualizada");
  // };

  const handleFinish = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    console.log("closing");
    setIsOpen(false);
  };

  const handleVote = (
    option,
    agendaId,
    meetingId,
    neighborId
  ) => {
    const body = JSON.stringify({
      neighbor_id: neighborId,
      meeting_agenda_id: agendaId,
      meeting_id: meetingId,
      vote: option,
    });

    const options = {
      method: "POST",
      body: body,
      headers: {
        // Aquí configuramos el tipo de contenido como 'multipart/form-data'
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    if (options.method === "POST") {
      setRegisterVoteUrl("/api/votes/");
    }
  }, [options]);

  useEffect(() => {
    setMeetingsUrl("/api/meetings/" + meetingId);
  }, []);

  if (meetingLoader || !meetingData) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Stepper onCallback={handleFinish}>
        <Card>
          <Box>
            <VStack spacing={6} align="start">
              <Heading as="h1" size="xl">
                {meetingData.title}
              </Heading>
              <Text>Hora de inicio: {meetingData.proposedTime1}</Text>
              {/* <Card boxShadow={cardShadow}>
                <HStack justify="space-between" w="100%">
                  <Box>
                    Conectados: {connectedMembers.current} de{" "}
                    {connectedMembers.total}
                  </Box>
                  <Box>
                    <Button colorScheme="blue" onClick={handleRefresh}>
                      <FaSync />
                    </Button>
                  </Box>
                </HStack>
              </Card> */}

              {/* Pods para los puntos del día */}
              {meetingData.meeting_agendas.map((item, index) => (
                <Card boxShadow={cardShadow} key={index}>
                  <Heading as="h3" size="md">
                    {item.title}
                  </Heading>
                  <Text>{item.description}</Text>
                </Card>
              ))}
            </VStack>
          </Box>
        </Card>
        {meetingData.meeting_agendas.map((agenda, index) => {
          return (
            <Step
              onVote={handleVote}
              key={index}
              agenda={agenda}
              attendees={meetingData.meeting_attendees}
            />
          );
        })}
      </Stepper>
      <FinishDialog isOpen={isOpen} onClose={handleClose} />
    </>
  );
};

export default MeetingRoom;
