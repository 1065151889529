import {
  VStack,
  Text,
  HStack,
  Badge,
  UnorderedList,
  ListItem,
  Box,
} from "@chakra-ui/react";
import { FaWhatsapp, FaEnvelope, FaSms } from "react-icons/fa";

const MeetingDetails = ({ data }) => {
  return (
    <Box p={6} borderRadius="md">
      <Text fontSize="xl" fontWeight="bold" mb={4}>
        Junta {data.meeting_type} para comunidad {data.neighborhood.name}
      </Text>
      <VStack align="flex-start" spacing={4} mt={4}>
        <Text>Lugar convocatoria: {data.location}</Text>
        <Text>Hora propuesta 1: {data.proposedTime1}</Text>
        <Text>Hora propuesta 2: {data.proposedTime2}</Text>

        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Canales de envío:
          </Text>
          <HStack spacing={2}>
            <Badge colorScheme="green" fontSize="sm" borderRadius="full">
              <HStack spacing={1}>
                <FaWhatsapp />
                <Text ml={1}>Whatsapp</Text>
              </HStack>
            </Badge>
            <Badge colorScheme="blue" fontSize="sm" borderRadius="full">
              <HStack spacing={1}>
                <FaEnvelope />
                <Text ml={1}>Correo electrónico</Text>
              </HStack>
            </Badge>
            <Badge colorScheme="yellow" fontSize="sm" borderRadius="full">
              <HStack spacing={1}>
                <FaSms />
                <Text ml={1}>SMS</Text>
              </HStack>
            </Badge>
          </HStack>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Mensaje de convocatoria:
          </Text>
          <Text>
            Estimados miembros de la comunidad {data.location}. Se convoca la
            próxima reunión de vecinos de caracter {data.meeting_type} para el
            próximo...
          </Text>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Ordenes del día incluidas:
          </Text>
          <UnorderedList>
            {data.meeting_agendas.map((item,i) => {
              return <ListItem key={item.title+i}>{item.title}</ListItem>
            })}
          </UnorderedList>
        </Box>
      </VStack>
    </Box>
  );
};

export default MeetingDetails;
