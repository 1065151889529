/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "views/admin/neighborhoods/components/ComplexTable";
import {
  columnsDataComplex,
} from "views/admin/neighborhoods/variables/columnsData";
import tableDataComplex from "views/admin/neighborhoods/variables/tableDataComplex.json";
import React, { useEffect, useState } from "react";

import { useFetch } from 'hooks';

export default function Neighborhoods() {
  const [neighborhoodsData, setNeighborhoodsData] = useState();
  const [neighborhoodsUrl, setNeigborhoodsUrl] = useState('');
  const { data, loading, error } = useFetch(neighborhoodsUrl);

  useEffect(() => {
    if (data) {
      const result = data.map((neighborhood) => {
        return {
          name: neighborhood.name,
          address: neighborhood.location,
          cif: neighborhood.cif,
          status: neighborhood.status
        }
      });
      setNeighborhoodsData(result);
    }
  },[data]);

  useEffect(() => {
    setNeigborhoodsUrl('/api/neighborhoods')
  },[]);
  
  // Chakra Color Mode
  if (loading || !neighborhoodsData) {
    return <p>Loading...</p>
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <ComplexTable
            columnsData={columnsDataComplex}
            tableData={neighborhoodsData}
          />
        </SimpleGrid>
    </Box>
  );
};
