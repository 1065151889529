// Chakra imports
import { Flex, Text, useColorModeValue, Button } from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import React, { useState } from "react";
import MeetingPod from "views/admin/meetings/components/MeetingPod";
import NeighborhoodSelectionDialog from './NeighborhoodSelectionDialog';
import { useHistory } from 'react-router-dom';

export default function NextMeetings({ data }) {
  const [meetingsData, setMeetingsData] = useState(
    data.map((meeting) => {
      return {
        date: meeting.date,
        location: meeting.location,
        meetingDescription: meeting.meetingDescription,
        meeting_type: meeting.meeting_type,
        name: meeting.name,
        proposedTime1: meeting.proposedTime1,
        proposedTime2: meeting.proposedTime2,
        id: meeting.id
      }
    }));

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const history = useHistory();

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  }

  const handleDetail = (item) => {
    history.push(`juntas/${item.id}`);
  }

  const handleDelete = (item) => {
    console.log('delete', item);
  };

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Flex justify='space-between' mb='20px' align='center'>
        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'>
          Próximas juntas
        </Text>
        <Button colorScheme="blue" onClick={handleOpenDialog}>Nueva junta</Button>
      </Flex>

      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Aquí puedes ver tus próximas juntas ordenadas por fecha más cercana a la fecha más lejana.
      </Text>
      {
        meetingsData.map((meeting, i) => {
          return (
            <MeetingPod
              key={meeting.name + i}
              boxShadow={cardShadow}
              mb='20px'
              ranking={i}
              item={meeting}
              onDetail={handleDetail}
              onDelete={handleDelete}
            />
          )
        })
      }
      <NeighborhoodSelectionDialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
    </Card>
  );
}
