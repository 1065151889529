import { useState, useEffect } from "react";
import { useFetch } from "hooks";
import { Box, Text, Button, Spinner } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useParams } from "react-router-dom";
import DualPane from "./components/DualPane";
import Loader from "components/Loader";
import { useHistory } from "react-router-dom";

const Record = () => {
  const history = useHistory();
  const [meetingsUrl, setMeetingsUrl] = useState("");
  const { data, loading, error } = useFetch(meetingsUrl);
  const [sendMinutesUrl, setSendMinutesUrl] = useState("");
  const {
    data: minutesData,
    loading: minutesLoading,
    error: minutesError,
  } = useFetch(sendMinutesUrl);
  const { recordId } = useParams();

  const handleSendMinutes = (meetingId) => {
    setSendMinutesUrl("/api/send-minutes/" + meetingId);
  };

  useEffect(() => {
    setMeetingsUrl("/api/meetings/" + recordId);
  }, [recordId]);

  useEffect(() => {
    if (minutesData) {
      history.push("../actas?is_confirmed=true");
    }
  }, [minutesData, history]);

  if (!data || loading) return <Loader />;

  return (
    <Box mt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card mb={{ base: "20px", "2xl": "20px" }}>
        <Box display="flex" alignItems="stretch" height="100%">
          <Box flex="1" pr={4}>
            <Text fontWeight="bold" fontSize="xl">
              Resumen de la Junta
            </Text>
          </Box>
          <Box flex="1" justifyContent="space-between" pl={4}>
            <Button
              onClick={() => handleSendMinutes(recordId)}
              width="100%"
              disabled={minutesLoading}
              colorScheme="blue"
            >
              {!minutesLoading && "Procesar y enviar acta al presidente"}
              {minutesLoading && <Spinner size="xs" />}
            </Button>
          </Box>
        </Box>
      </Card>
      {data.meeting_agendas.map((agenda, i) => {
        return (
          <DualPane
            key={i}
            agenda={agenda}
            votes={data.votes.filter(
              (vote) => vote.meeting_agenda_id === agenda.id
            )}
            attendees={data.meeting_attendees}
          />
        );
      })}
    </Box>
  );
};

export default Record;
