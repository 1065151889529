import { Box, Text, Button, VStack, HStack, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const MeetingStartPod = ({ data }) => {
  const history = useHistory();

  const rawMeetingDate = new Date(data.date);
  const rawCurrentDate = new Date();

  // Calcula la diferencia en días
  const daysLeft = Math.ceil(Math.abs(rawMeetingDate - rawCurrentDate) / (1000 * 60 * 60 * 24));

  const handleClick = () => {
    history.push('../meeting-room/'+data.id);
  }
  
  return (
    <Box
      p={6}
      color="gray.100"
      borderRadius="md"
      display="flex"
      flexDirection="column"
    >
      <Text fontSize="xl" fontWeight="bold" mb={2}>
        Datos de la convocatoria
      </Text>
      <VStack align="start" spacing={4} mt={4}>
        <Box>
          <Text fontWeight="bold" mb={1}>
            Fecha:
          </Text>
          <Text fontSize="2xl">{data.date}</Text>
          <Text fontSize="xl" fontStyle="italic" mt={2}>
            Quedan {daysLeft} días
          </Text>
        </Box>
        <Box w="100%">
          <HStack justify="space-between" w="100%">
            <Text fontWeight="bold" mb={1}>
              Convocados
            </Text>
            <Text>{data.meeting_attendees.length}</Text>
          </HStack>
        </Box>
        <Box w="100%">
          <HStack justify="space-between" w="100%">
            <Text fontWeight="bold" mb={1}>
              Confirmados totales
            </Text>
            <Text>
              {
                data.meeting_attendees.filter(item => item.confirmed === 1).length
              }
            </Text>
          </HStack>
        </Box>
        <Box w="100%">
          <HStack justify="space-between" w="100%">
            <Text fontWeight="bold" mb={1}>
              Conf. con derecho a voto
            </Text>
            <Text>
              {
                data.meeting_attendees.filter(item => item.confirmed === 1).length
              }
            </Text>
          </HStack>
        </Box>
      </VStack>
      <VStack align="bottom" mt="20px">
        <Button colorScheme="green" onClick={handleClick}>Empezar junta</Button>
        <Button colorScheme="blue" mt={2}>
          Previsualizar convocatoria
        </Button>
      </VStack>
    </Box>
  );
};

export default MeetingStartPod;
