import { useEffect, useState } from "react";
import {
  Button,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { useParams } from "react-router-dom";

const FinishDialog = ({ isOpen, onClose }) => {
  const [text, setText] = useState("");
  const history = useHistory();
  const [finishUrl, setFinishUrl] = useState("");
  const [options, setOptions] = useState({});
  const { data, loading, error } = useFetch(finishUrl, options);
  const { meetingId } = useParams();

  const handleClose = () => {
    // Realizar acciones de finalización aquí
    onClose();
  };

  const handleFinish = () => {
    const body = JSON.stringify({
      is_finished: true,
      message: text,
    });

    const options = {
      method: "PUT",
      body: body,
      headers: {
        // Aquí configuramos el tipo de contenido como 'multipart/form-data'
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    if (options.method) {
      setFinishUrl(`/api/meetings/${meetingId}/update-finished-status/`);
    }
  }, [options]);

  useEffect(() => {
    if (data) {
      history.push("../actas?message=finished");
    }
  }, [data]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>¿Deseas añadir algo más?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Escribe aquí..."
          />
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" onClick={handleFinish}>
            Finalizar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FinishDialog;
