import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Divider,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { HStack, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { FaCheck, FaTimes } from "react-icons/fa";
import Card from "components/card/Card.js";
import { useFetch } from "hooks";
import { useParams, useHistory } from "react-router-dom";
import { getNeighborDebt, canNeighborVote } from "utils";

const ConfirmationDialog = ({ isOpen, onClose, meetingData }) => {
  const [meetingUrl, setMeetingUrl] = useState("");
  const [options, setOptions] = useState({});
  const { data, loading, error } = useFetch(meetingUrl, options);
  const { neighborhoodId } = useParams();
  const history = useHistory();

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const handleSubmit = () => {
    const body = JSON.stringify(meetingData);

    const options = {
      method: "POST",
      body: body,
      headers: {
        // Aquí configuramos el tipo de contenido como 'multipart/form-data'
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    setOptions(options);
  };

  useEffect(() => {
    if (options.method) {
      setMeetingUrl(`/api/neighborhoods/${neighborhoodId}/meetings`);
    }
  }, [options]);

  useEffect(() => {
    if (data) history.push("../../juntas?message=success");
  }, [data]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmación</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Card bgColor="gray.200" boxShadow={cardShadow}>
            <VStack align="start" spacing={4}>
              <Text>
                La convocatoria ya está lista, por favor, revisa que todo esté
                correcto.
              </Text>
              <Text fontWeight="bold">
                Convocatoria de {meetingData.title} de{" "}
                {meetingData.neighborhood}
              </Text>
              <Text>Tipo de junta: {meetingData.meeting_type}</Text>
              <Text>Dónde se celebra la junta: {meetingData.location}</Text>
              <Text>Fecha: {meetingData.date}</Text>
              <Text>Hora propuesta 1: {meetingData.proposedTime1}</Text>
              <Text>Hora Propuesta 2: {meetingData.proposedTime2}</Text>
              <Divider />
              <Text fontWeight="bold">
                Vecinos a quienes se les envía la convocatoria:
              </Text>
              {meetingData.neighbors.map((neighbor) => (
                <HStack key={neighbor.id}>
                  <Tag
                    colorScheme={
                      neighbor.properties.every(
                        (property) =>
                          property.debt &&
                          parseFloat(property.debt.replace(",", ".")) > 0
                      )
                        ? "red"
                        : "green"
                    }
                  >
                    <TagLabel>
                      <strong>
                        {neighbor.name} {neighbor.first_surname}
                      </strong>
                    </TagLabel>
                  </Tag>
                  {!canNeighborVote(neighbor.properties) && (
                    <Tag colorScheme="red">
                      <TagLabel>
                        No puede votar, debe{" "}
                        {getNeighborDebt(neighbor.properties)} €
                      </TagLabel>
                    </Tag>
                  )}
                </HStack>
              ))}
              <Divider />
              <Text fontWeight="bold">Mensaje de convocatoria:</Text>
              <Text>{meetingData.meetingDescription}</Text>
              <Divider />
              <Text fontWeight="bold">Órden del día:</Text>
              {meetingData.agendaItems
                .filter((item) => item.checked)
                .map((item) => (
                  <VStack key={item.id} align="start">
                    <HStack>
                      <Tag colorScheme="green">
                        <TagLeftIcon as={FaCheck} />
                        <TagLabel>{item.title}</TagLabel>
                      </Tag>
                    </HStack>
                  </VStack>
                ))}
            </VStack>
          </Card>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-between">
          <Button ml={3} onClick={onClose}>
            Atrás
          </Button>
          <Button
            disabled={loading}
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
          >
            {!loading && "Convocar"}
            {loading && <Spinner size="xs" />}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationDialog;
