/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  Spinner
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth2.jpeg";
import logo from "assets/img/layout/logo-white.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { setToken, getApiUrl } from "utils";

const SignIn = () => {
  // Chakra color mode
  const textColor = useColorModeValue("#00b5d8", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("#00b5d8", "secondaryGray.600");
  const textColorBrand = useColorModeValue("#00b5d8", "white");
  const brandStars = useColorModeValue("#00b5d8.500", "brand.400");
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  

  const handleEmailChange = (email) => setEmail(email);
  const handlePasswordChange = (password) => setPassword(password);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    // Aquí puedes realizar la lógica de envío del formulario
    try {
      const response = await fetch(getApiUrl()+"/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        setToken('Bearer '+responseData.token);
        history.push("../../admin/default");
        // Login exitoso, redirigir o mostrar mensaje de éxito
      } else {
        // Error en el login, mostrar mensaje de error o hacer alguna acción adicional
        setError("Usuario o contraseña incorrecta");
      }
    } catch (error) {
      // Manejo de errores de red u otros errores
      setError("Algo va mal en nuestro servidor:", error);
    }
    setLoader(false);
  };

  const handleClick = () => setShow(!show);
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} mb="10px">
            <img
              width="200px"
              style={{ marginLeft: "-9px" }}
              src={logo}
              alt="VECINFY"
            />
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Bienvenido al futuro de la administración de fincas
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              Accede
            </Text>
            <HSeparator />
          </Flex>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              ``
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                name="email"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
                placeholder="tuemail@vecinfy.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Por favor, introduce una dirección de correo electrónico válida."
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Contraseña<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Mínimo 8 carácteres"
                  mb="24px"
                  size="lg"
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  value={password}
                  type={show ? "text" : "password"}
                  variant="auth"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="vecinfy"
                    me="10px"
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    Manténme conectado
                  </FormLabel>
                </FormControl>
                {/* <NavLink to="/auth/forgot-password"> */}
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Has olvidado tu contraseña?
                </Text>
                {/* </NavLink> */}
              </Flex>
              {error && (
                <Box pb={{ base: "20px", md: "20px", xl: "20px" }}>
                  <Alert
                    status="error"
                    variant="subtle"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <AlertIcon />
                    {error}
                  </Alert>
                </Box>
              )}
              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                {loader && <Spinner size="xs"/>}
                {!loader && 'Entrar'}
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Aún no eres un administrador Vecinfy?
              {/* <NavLink to="/auth/sign-up"> */}
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Crea una cuenta!
              </Text>
              {/* </NavLink> */}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default SignIn;
