import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
import isologo from 'assets/img/layout/isologo.svg';
import { HSeparator } from "components/separator/Separator";

const SidebarBrand = () => {
  return (
    <Flex align='center' direction='column'>
      <Image style={{ 'padding': '18px' }} src={isologo}></Image>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
