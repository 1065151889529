import { useEffect, useState } from "react";
import {
  VStack,
  Heading,
  Text,
  Box,
  Textarea,
  Button,
  HStack,
  Divider,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import useFetch from "hooks/useFetch";
import { generateOptions, canNeighborVote } from "utils";

const Step = ({ agenda, attendees, onVote }) => {
  const [agendaState, setAgendaState] = useState(agenda);
  const [att, setAtt] = useState(attendees);
  const [votesUrl, setVotesUrl] = useState("");
  const [votes, setVotes] = useState(
    attendees.map((att) => {
      return {
        neighbor_id: att.neighbor_id,
        vote: null,
      };
    })
  );
  const {
    data: votesData,
    loading: votesLoader,
    error: votesError,
  } = useFetch(votesUrl);
  const [saveConclusionsUrl, setSaveConclusionsUrl] = useState("");
  const [options, setOptions] = useState({});
  const {
    data: conclusionsData,
    loading: conclusionsLoader,
    error: conclusionsError,
  } = useFetch(saveConclusionsUrl, options);

  const handleOptionClick = (option, id) => {
    setVotes((prevState) => {
      return prevState.map((ps) => {
        if (ps.neighbor_id === id) {
          return {
            ...ps,
            vote: option,
          };
        }
        return ps;
      });
    });
    return onVote(option, agenda.id, agenda.meeting_id, id);
  };

  const handleConclusionsChange = (conclusion) => {
    setAgendaState((prevState) => {
      return {
        ...prevState,
        conclusions: conclusion,
      };
    });
  };

  const handleConclusionsSave = () => {
    const body = JSON.stringify({
      conclusions: agendaState.conclusions,
    });
    setOptions(generateOptions("PUT", body));
  };

  useEffect(() => {
    if (options.method) {
      setSaveConclusionsUrl("/api/meeting-agenda/" + agenda.id);
    }
  }, [options]);

  useEffect(() => {
    setVotesUrl("/api/votes/agenda/" + agenda.id);
  }, []);

  useEffect(() => {
    if (votesData) {
      setVotes((prevState) => {
        return prevState.map((ps) => {
          // Verificar si el neighbor_id coincide con algún elemento en votesData
          const matchingVoteData = votesData.find(
            (vd) => vd.neighbor_id === ps.neighbor_id
          );
          if (matchingVoteData) {
            // Agregar votesData a este elemento
            return matchingVoteData;
          }
          // Si no hay coincidencias, mantener el estado sin cambios
          return ps;
        });
      });
    }
  }, [votesData]);

  if (votesLoader || !votesData) return <p>Loading...</p>;

  return (
    <Card>
      <VStack align="start" spacing={6}>
        {/* Título de la junta de vecinos */}
        <Heading as="h2" size="lg">
          {agenda.title}
        </Heading>

        {/* Título del punto del día */}
        <Box>
          <Text mt={4}>{agenda.description}</Text>
        </Box>

        {/* Pods de anotaciones públicas y privadas */}
        <VStack align="start" spacing={4}>
          <Box bg="gray.100" p={4} borderRadius="md" w="100%">
            <Text fontWeight="bold">Anotaciones públicas</Text>
            <Text>{agenda.public_notes}</Text>
          </Box>
          <Box bg="gray.100" p={4} borderRadius="md" w="100%">
            <Text fontWeight="bold">Anotaciones privadas</Text>
            <Text>{agenda.private_notes}</Text>
          </Box>
        </VStack>

        {/* Textarea para escribir conclusiones */}
        <Textarea
          onBlur={handleConclusionsSave}
          value={agendaState.conclusions || ""}
          onChange={(e) => handleConclusionsChange(e.target.value)}
          placeholder="Escribe tus conclusiones aquí"
          w="100%"
        />

        <Heading as="h3" size="md">
          Votaciones
        </Heading>
        {att.map((attendee, index) => {
          const { vote } = votes.find(
            (voteData) => voteData.neighbor_id === attendee.neighbor_id
          ) || { vote: null };

          const canVote = canNeighborVote(attendee.properties);

          return (
            <>
              <HStack
                key={attendee.name + index}
                spacing={4}
                justify="space-between"
                w="100%"
              >
                <Text flex="1">
                  {`${attendee.name} ${attendee.first_surname}`}
                </Text>
                {canVote ? (
                  <>
                    <Button
                      maxW="120px"
                      flex="1"
                      colorScheme={vote === "abstention" ? "blue" : "gray"}
                      variant={vote === "abstention" ? "solid" : "outline"}
                      onClick={() =>
                        handleOptionClick("abstention", attendee.neighbor_id)
                      }
                    >
                      Abstención
                    </Button>
                    <Button
                      maxW="80px"
                      flex="1"
                      colorScheme={vote === "false" ? "red" : "gray"}
                      variant={vote === "false" ? "solid" : "outline"}
                      onClick={() =>
                        handleOptionClick("false", attendee.neighbor_id)
                      }
                    >
                      No
                    </Button>
                    <Button
                      maxW="80px"
                      flex="1"
                      colorScheme={vote === "true" ? "green" : "gray"}
                      variant={vote === "true" ? "solid" : "outline"}
                      onClick={() =>
                        handleOptionClick("true", attendee.neighbor_id)
                      }
                    >
                      Sí
                    </Button>
                  </>
                ) : (
                  <Text>
                    <strong>No puede votar</strong>
                  </Text>
                )}
              </HStack>
              <Divider />
            </>
          );
        })}
      </VStack>
    </Card>
  );
};

export default Step;
