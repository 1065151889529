import Card from "components/card/Card.js";
import { HSeparator } from "components/separator/Separator";
import { Input, Button, Heading, Stack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const PropertiesForm = ({ disabled, data, onValidate }) => {
  const [properties, setProperties] = useState([{
    location: '',
    neighbor_name: '',
    neighbor_email: '',
    neighbor_id: '',
  }]);

  useEffect(() => {
    if(data[0] && data[0].location !== ''){
      setProperties(data.map((property) => {
        return {
          location: property.location || '',
          neighbor_name: property.neighbor_name || '',
          neighbor_email: property.neighbor_email || '',
          neighbor_id: property.neighbor_id || ''
        }
      }));
    }
  }, [data]);

  const handleInputChange = (index, field, value) => {
    const newProperties = [...properties];
    newProperties[index] = { ...newProperties[index], [field]: value };
    setProperties(newProperties);
  };

  const handleAddProperty = () => {
    setProperties([...properties, {
      location: '',
      neighbor_name: '',
      neighbor_email: '',
      neighbor_id: data[0].neighbor_id
    }]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario
    onValidate('properties', properties);
  };

  return (
    <Card shadow bg={disabled ? "gray.200" : "white"} opacity={disabled ? 0.7 : 1}>
      <Heading as="h2" size="lg" mb={4}>Propiedades</Heading>
      <form onSubmit={handleSubmit} disabled={disabled}>
        <Stack spacing={4} mb={4}>
          {properties.map((property, index) => (
            <div key={index}>
              <Input
                value={property.location}
                onChange={(e) => handleInputChange(index, 'location', e.target.value)}
                placeholder={`Dirección de la propiedad ${index + 1}`}
                disabled={disabled}
                mb={2}
              />
              <Input
                value={property.neighbor_name}
                onChange={(e) => handleInputChange(index, 'neighbor_name', e.target.value)}
                placeholder={`Propietario de la propiedad ${index + 1}`}
                disabled={true}
                mb={4}
              />
              <Input
                value={property.neighbor_email}
                onChange={(e) => handleInputChange(index, 'neighbor_email', e.target.value)}
                placeholder={`Email del propietario ${index + 1}`}
                disabled={disabled}
                mb={4}
              />
              <HSeparator />
            </div>
          ))}
        </Stack>
        <Stack direction="row" spacing={4} mb={4}>
          <Button onClick={handleAddProperty} disabled={disabled} flex="1">
            Añadir propiedad
          </Button>
          <Button type="submit" disabled={disabled} flex="1">
            Validar
          </Button>
        </Stack>
      </form>
    </Card>
  );
};

export default PropertiesForm;
