import Card from "components/card/Card.js";
import { Input, Button, Heading, Stack, FormLabel } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const NeighborhoodForm = ({ disabled, data, onValidate }) => {
  const [communityData, setCommunityData] = useState({
    location: data.location,
    cif: data.cif
  });

  console.log('comdata: ', communityData, data);

  useEffect(() => {
    // Actualizar communityData cuando cambia la prop data
    setCommunityData({
      location: data.name || data.location || '',
      cif: data.cif || ''
    });
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommunityData({ ...communityData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario
    console.log('Datos de la comunidad:', communityData);
    onValidate('neighborhood', communityData);
  };

  return (
    <Card title="Formulario de Comunidad" shadow bg={disabled ? "gray.200" : "white"} opacity={disabled ? 0.7 : 1}>
      <Heading as="h2" size="lg" mb={4}>Datos de la comunidad</Heading>
      <form onSubmit={handleSubmit} disabled={disabled}>
        <FormLabel>Dirección de la comunidad</FormLabel>
        <Input
          name="location"
          value={communityData.location}
          onChange={handleChange}
          placeholder="Dirección de la comunidad"
          disabled={disabled}
          mb={4}
        />
        <FormLabel>CIF de la comunidad</FormLabel>
        <Input
          name="cif"
          value={communityData.cif}
          onChange={handleChange}
          placeholder="CIF de la comunidad"
          disabled={disabled}
          mb={4}
        />
        <Stack direction="row" spacing={4} mb={4}>
          <Button disabled={disabled} type="submit">Validar</Button>
        </Stack>
      </form>
    </Card>
  );
};

export default NeighborhoodForm;
