/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useFetch } from "hooks";

import {
  Box,
  Divider,
  VStack,
  Text,
  Textarea,
  Button,
  HStack,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { generateOptions } from "utils";

const DualPaneComponent = ({ agenda, votes, attendees }) => {
  console.log(attendees, votes);
  const {
    title,
    description,
    public_notes,
    private_notes,
    conclusions,
    id,
    ai_output,
  } = agenda;

  const positiveVotes = votes
    .filter(({ vote }) => vote === "true")
    .map(({ neighbor_id }) => neighbor_id);
  const positiveNeighbors = attendees.filter((attendee) =>
    positiveVotes.includes(attendee.neighbor_id)
  );
  const negativeVotes = votes
    .filter(({ vote }) => vote === "false")
    .map(({ neighbor_id }) => neighbor_id);
  const negativeNeighbors = attendees.filter((attendee) =>
    negativeVotes.includes(attendee.neighbor_id)
  );
  const abstentionVotes = votes
    .filter(({ vote }) => vote === "abstention")
    .map(({ neighbor_id }) => neighbor_id);
  const abstentionNeighbors = attendees.filter((attendee) =>
    abstentionVotes.includes(attendee.neighbor_id)
  );

  console.log(positiveNeighbors);

  const openAiBody = {
    public_notes: public_notes,
    private_notes: private_notes,
    conclusions: conclusions,
    title: title,
    description: description,
    votes_for: positiveNeighbors,
    votes_against: negativeNeighbors,
    votes_abstention: abstentionNeighbors,
    ai_output: ai_output,
    template: title === "Elección o renovación de cargos" && "newpresident",
  };
  const [conclusionsUrl, setConclusionsUrl] = useState("");
  const [options, setOptions] = useState(
    generateOptions("POST", JSON.stringify(openAiBody))
  );

  const {
    data: conclusionsData,
    loading: conclusionsLoader,
    error: conclusionsError,
  } = useFetch(conclusionsUrl, options);
  const [aiOutputUrl, setAiOutputUrl] = useState("");
  const [aiOptions, setAiOptions] = useState({});
  const {
    data: aiData,
    loading: aiLoader,
    error: aiError,
  } = useFetch(aiOutputUrl, aiOptions);
  const [summary, setSummary] = useState(ai_output);
  const [aiSeed, setAiSeed] = useState(0);

  const handleNewGeneration = () => {
    setAiSeed(Math.random() * (100000 - 1) + 1);
    setOptions((prevState) => {
      return {
        ...prevState,
        method: "POST",
      };
    });
  };

  const handleChange = (text) => {
    setSummary(text);
  };

  const handleBlur = (summary) => {
    const aiOutputBody = JSON.stringify({
      ai_output: summary.length > 0 ? summary : "",
    });

    setAiOptions(generateOptions("PUT", aiOutputBody));
  };

  useEffect(() => {
    if (aiOptions.method === "PUT") setAiOutputUrl("/api/meeting-agenda/" + id);
  }, [aiOptions]);

  useEffect(() => {
    if (options.method === "POST" && (!openAiBody.ai_output || aiSeed > 0)) {
      setConclusionsUrl("/api/openai/generate-conclusion/");
    }
  }, [options, aiSeed]);

  useEffect(() => {
    if (conclusionsData && conclusionsData.content) {
      setSummary(conclusionsData.content);
      setAiOptions(
        generateOptions(
          "PUT",
          JSON.stringify({
            ai_output: conclusionsData.content,
          })
        )
      );
      setOptions((prevState) => {
        return {
          ...prevState,
          method: "",
        };
      });
    }
  }, [conclusionsData]);

  return (
    <Card mb={{ base: "20px", "2xl": "20px" }}>
      <Box display="flex" alignItems="stretch" height="100%">
        {/* Bloque izquierdo */}
        <Box flex="1" pr={4}>
          <VStack align="start" spacing={4}>
            <Text fontWeight="bold">{title}</Text>
            {/* Lista de elementos */}
            <VStack align="start" spacing={2}>
              <Text fontWeight="bold">Descripción:</Text>
              <Text>{description}</Text>
              <Text fontWeight="bold">Anotaciones públicas:</Text>
              <Text>{public_notes}</Text>
              <Text fontWeight="bold">Anotaciones privadas:</Text>
              <Text>{private_notes}</Text>
              <Text fontWeight="bold">Conclusiones del punto:</Text>
              <Text>{conclusions}</Text>
              {/* Agrega más elementos aquí según sea necesario */}
            </VStack>
          </VStack>
        </Box>
        {/* Divider vertical */}
        <Divider orientation="vertical" height="100%" borderColor="gray" />
        {/* Bloque derecho */}
        <Box flex="1" pl={4}>
          <VStack align="center" height="100%" spacing={4}>
            {/* Campo de texto */}
            {conclusionsLoader && <Spinner size="xl" />}
            {!conclusionsLoader && (
              <Textarea
                height="100%"
                onBlur={(e) => handleBlur(e.target.value)}
                onChange={(e) => handleChange(e.target.value)}
                placeholder="Escribe aquí..."
                value={summary}
              />
            )}

            {/* Botones */}
            <HStack justify="space-between" w="100%">
              <Button
                disabled={conclusionsLoader}
                colorScheme="blue"
                onClick={handleNewGeneration}
              >
                Otra propuesta
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Box>
      {/* Accordion */}
      <Accordion allowToggle mt={4}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Ver votación desglosada
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {/* Tabla dentro del AccordionPanel */}
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Propietario/a</Th>
                  <Th>Núm. propiedades</Th>
                  <Th>Cuotas de participación (%)</Th>
                  <Th>A favor</Th>
                  <Th>En Contra</Th>
                  <Th>Abstención</Th>
                </Tr>
              </Thead>
              <Tbody>
                {attendees.map((attendee, index) => {
                  const completeVote = votes.find(
                    (vote) => vote.neighbor_id === attendee.neighbor_id
                  );
                  return (
                    <Tr key={index}>
                      <Td>
                        {attendee.name} {attendee.first_surname}
                      </Td>
                      <Td>{attendee.properties.length}</Td>
                      <Td>
                        {attendee.properties.reduce(
                          (acc, curr) => acc + parseFloat(curr.ratio),
                          0
                        )}
                      </Td>
                      <Td>
                        {completeVote && completeVote.vote === "true" && "X"}
                      </Td>
                      <Td>
                        {completeVote && completeVote.vote === "false" && "X"}
                      </Td>
                      <Td>{!completeVote && "X"}</Td>
                    </Tr>
                  );
                })}
                {/* Agrega más filas según tus datos */}
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default DualPaneComponent;
