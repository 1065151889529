// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
// Assets
import { FaEye } from 'react-icons/fa';

export default function Project(props) {
  const { item, onDetail, onDelete, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p='14px'>
      <Flex align='center' justifyContent="space-between" direction={{ base: "column", md: "row" }}>
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight='500'
            fontSize='md'
            mb='4px'>
            {item.name}
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            me='4px'>
            {item.location}
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            me='4px'>
            {item.date}
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            me='4px'>
            {item.proposedTime1}
          </Text>
        </Box>
        <Box>
          <Link
            onClick={() => onDetail(item)}
            variant='no-hover'
            me='16px'
            ms='auto'
            p='0px !important'>
            <Icon as={FaEye} color='secondaryGray.500' h='18px' w='18px' />
          </Link>

        </Box>
      </Flex>
    </Card>
  );
}
