import { format, addMinutes } from "date-fns";

/**
 * Add specified number of minutes to a given time.
 * @param {string} time - The time string in format "HH:mm".
 * @param {number} minutesToAdd - The number of minutes to add.
 * @returns {string} - The time string with added minutes.
 */
const addMinutesToTime = (time, minutesToAdd) => {
  // Parse the time string to a Date object
  const timeDate = new Date(`2000-01-01T${time}:00`);

  // Add the specified number of minutes using date-fns
  const newTimeDate = addMinutes(timeDate, minutesToAdd);

  // Format the result back to a string in the desired format
  return format(newTimeDate, "HH:mm");
};

export default addMinutesToTime;
